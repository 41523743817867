import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import { baseUrl, dateFormate } from "../../config/config";
import { apiService } from "../../service/api.service";
import { useNavigate, Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../loader";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const CultivatorDetails = () => {
  const { t } = useTranslation();
  let props = useParams();
  const [bankFields, setbankFields] = useState({
    account_number: "",
     routing_number: "",
    account_holder_name: "",
    bank_name: "STRIPE TEST BANK",
    terms_and_conditions:false,
    account_holder_type:"individual"
  });
  const [deactivatedReason, setDeactivatedReason] = useState("");
  const [userId, setUserId] = useState();
  const [show, setShow] = useState(false);
  const [reasonError, setReasonError] = useState("");
  const [loader, setLoader] = useState(false);
  const [profileData, setprofileData] = useState(false);
  const [show_bank_info, setShow_bank_info] = useState(false);
  const [previewImage, setpreviewImage] = useState();
  const [show_img_info, setShow_img_info] = useState(false);
  const handleClose_bank_info = () => setShow_bank_info(false);
  const handleShow_bank_info = () => {
   setShow_bank_info(true);
  }
  useEffect(() => {
    get_usersDetail();
  }, []);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    setUserId(props.id);
    setShow(true);
  };
  async function get_usersDetail() {
    setLoader(true);
    try {
      const response = await apiService.get_user_detail(props.id);
      if (response?.status == 200) {
        setLoader(false);
        let responseData = response.data.data;
        setprofileData(response.data.data.resource);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(
            () => {
              localStorage.clear();
              window.location.href = "/";
              console.log("OK button clicked after error alert");
            }
          );
        }
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }
  const handleClose_img_info = () => {
    setpreviewImage()
    setShow_img_info(false);
  }
  function disableUser() {
    if (deactivatedReason.trim() !== "") {
      accountVerification("Reject", userId);
      // console.log("??????????????saveeeebutton =======", deactivatedReason)
      // userStatus(userId)
      setReasonError("");
      setShow(false);
    } else {
      setReasonError(t("enter_reason"));
    }
  }
  const getbankDetail = (userid) => { 
    apiService.getBank(userid).then((res) => {
     console.log(res)
     if(res.data.data.resource==null){
      swal("",t("no_bank"),"warning")
      return
     }
       let bankDetail={}
       let bankDatas =   res.data.data.resource
       bankDetail.account_numberShow=bankDatas.accountNumber
       bankDetail.accountNumber=bankDatas.accountNumber
       bankDetail.accountTypeLabel=bankDatas.accountTypeLabel
       bankDetail.bankCode=bankDatas.bankName
       bankDetail.documentNumber=bankDatas.documentNumber
       bankDetail.documentTypeLabel=bankDatas.documentTypeLabel
       bankDetail.expeditionDate=bankDatas.expeditionDate
       bankDetail.fullName=bankDatas.fullName
       bankDetail.birthDate=bankDatas.birthDate
       setbankFields(bankDetail)
       handleShow_bank_info()
  
     }).catch((err) => {
         setLoader(false)
         // console.log(err)
         console.log("contact us api error", err.response);
     });
 }
 const handleShow_img_info = (img) => {
  console.log(img)
  setpreviewImage(img)
  setShow_img_info(true);
}
  const accountVerification = async (status, id) => {
    setLoader(true);
    try {
      const response = await apiService.acceptReject(
        id,
        status,
        deactivatedReason
      );
      if (response?.status == 200 || response?.status == 201) {
        // get_users(page, size, role, search,statusEnabled)
        get_usersDetail();
        setLoader(false);
        console.log("response======", response);
        // console.log("response======", status.lowerCase())
        if(status=="Accept"){
          swal(
            t("success"),
            t("profile_accepted"),
            "success"
          );
        }else{
          swal( t("success"),
          t("profile_rejected"),
          "success");
        
        }
       
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload();
        } else {
          swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(
            () => {
              localStorage.clear();
              window.location.href = "/";
              console.log("OK button clicked after error alert");
            }
          );
        }
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  };
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">{t("user_detail")}</h2>
                  </div>
                </div>
              </Col>
            </Row>
            {/*  */}
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className="user-details-section">
                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="user-img">
                              <img
                                src={
                                  profileData?.imagePath
                                    ? baseUrl + profileData?.imagePath
                                    : require("../../assets/images/Portrait_Placeholder.png")
                                }
                                alt=""
                                className="user-img"
                              />
                            </div>
                            <div className="accept-reject">
                              {profileData.status == "Pending" ? (
                                <>
                                  <Button
                                    className="accept-btn"
                                    onClick={() => {
                                      swal({
                                        title:t("are_you_sure"),
                                        text: t("accept_want"),
                                        icon: "warning",
                                        buttons: [t("cancel"), t("yes_accept")],
                                        dangerMode: true,
                                      }).then((confirmed) => {
                                        if (confirmed) {
                                          accountVerification(
                                            "Accept",
                                            profileData.id
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    <i class="fa-solid fa-check"></i> {t("accept")}
                                  </Button>
                                  <Button
                                    className="accept-btn reject-btn ms-2"
                                    onClick={() => {
                                      swal({
                                        title: t("are_you_sure"),
                                        text:t("reject_want"),
                                        icon: "warning",
                                        buttons: [t("cancel"), t("yes_reject")],
                                        dangerMode: true,
                                      }).then((confirmed) => {
                                        if (confirmed) {
                                          handleShow(profileData.id);
                                        }
                                      });
                                    }}
                                  >
                                    <i class="fa-solid fa-close text-light"></i>{" "}
                                    {t("reject")}
                                  </Button>
                                </>
                              ) : (
                                <div className="status-btns">
                                  <p className="m-0 p-0">{t("account_status")}: </p>
                                  {profileData.status == "Pending" ? (
                                    <Link className="delivered waiting">
                                      {" "}
                                      {t("pending")}
                                    </Link>
                                  ) : (
                                    <>
                                      {profileData.status == "Accept" ? (
                                        <Link className="delivered ">
                                          {" "}
                                          {t("accepted")}
                                        </Link>
                                      ) : (
                                        <>
                                          {profileData.status == "Reject" ? (
                                            <Link className="delivered rejected">
                                              <b>{t("request_rejected")}
                                               </b>
                                            </Link>
                                          ) : (
                                            <Link className="delivered waiting">
                                              {t("incomplete_profile")}
                                             
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="user-text">
                            <p> {t("full_name")}
                             </p>
                          
                              <h5>
                                {profileData?.firstName
                                  ? profileData?.firstName +
                                    " " +
                                    profileData?.lastName
                                  : "N/A"}
                              </h5>
                       
                          </div>
                        </Col>
                        
                        {profileData?.role !== "ROLE_USER" && (
                          <Col md={6}>
                            <div className="user-text">
                              <p>{t("email_address")}</p>
                              <h5>
                                {profileData?.email
                                  ? profileData?.email
                                  : "N/A"}
                              </h5>
                            </div>
                          </Col>
                        )}
                        <Col md={6}>
                          <div className="user-text">
                            <p>{t("phone_number")}</p>
                            <h5>
                              {profileData?.phone
                                ?  profileData?.phone
                                : "N/A"}
                            </h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="user-text">
                            <p>{t("user_type")}</p>
                            <h5>{"Cultivator"}</h5>
                          </div>
                        </Col>
                        {profileData?.role == "ROLE_CULTIVATOR" ? (
                          <Col md={6}>
                            <div className="user-text">
                              <p>{t("experience")}
                                </p>
                              <h5>
                                {profileData?.cultivator?.yearOfExperience
                                  ? profileData?.cultivator?.yearOfExperience
                                  : "N/A"}
                              </h5>
                            </div>
                          </Col>
                        ) : null}
                        <Col md={6}>
                          <div className="user-text">
                            <p>{t("business_name")}
                              </p>
                            <h5>
                              {profileData?.cultivator?.businessName
                                ? profileData?.cultivator.businessName
                                : "N/A"}
                            </h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="user-text">
                            <p>{t("gender")}</p>
                            <h5>
                              {profileData?.gender
                                ? profileData?.gender
                                : "N/A"}
                            </h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="user-text">
                            <p>{t("address")}</p>
                            <h5>
                              {profileData?.address
                                ? profileData?.address
                                : "N/A"}
                            </h5>
                          </div>
                        </Col>
                        {profileData?.role == "ROLE_CULTIVATOR" && (
                          <>
                            <Col md={6}>
                              <div className="user-text">
                                <p>{t("cultivation_type")}</p>
                                <h5>
                                  {profileData?.cultivator?.typeOfCultivation
                                    ? profileData?.cultivator.typeOfCultivation
                                    : "N/A"}
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="user-text">
                                <p>{t("city")}</p>
                                <h5>
                                  {profileData?.cultivator?.city
                                    ? profileData?.cultivator?.city
                                    : "N/A"}
                                </h5>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="user-text">
                                <p>{t("country")}</p>
                                <h5>
                                  {profileData?.cultivator?.country
                                    ? profileData?.cultivator?.country
                                    : "N/A"}
                                </h5>
                              </div>
                            </Col>
                          </>
                        )}
                        {profileData.reason && (
                          <Col md={6}>
                            <div className="user-text">
                              <p>{t("rejection_reason")}</p>
                              <h5>
                                {profileData?.reason
                                  ? profileData?.reason
                                  : "N/A"}
                              </h5>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col md={4}>
                      <div className="id-proof-area">
                      <h6 className="id-heading">{t("id_proof")}
                        </h6>
                      <Row>
                        <Col md={6}>
                          {profileData?.role == "ROLE_CULTIVATOR" &&
                            profileData?.cultivator?.idProofFront && (
                              <div className="document-information ">
                                <h4> {t("front_id")}</h4>

                                <div className="uploaded-files mt-1">
                                  <img
                                    src={
                                      baseUrl +
                                      profileData?.cultivator?.idProofFront
                                    }
                                    alt=""
                                  />
                                    <a href="#" onClick={() => handleShow_img_info(baseUrl + profileData?.cultivator?.idProofFront)} ><p>{t("preview_image")}
                                       </p></a>
                                  {/* <a href={baseUrl+profileData?.cultivator?.documentPath} target="_blank"><p>Preview Doc</p></a> */}
                                </div>
                              </div>
                            )}
                        </Col>
                        <Col md={6}>
                          {profileData?.role == "ROLE_CULTIVATOR" &&
                            profileData?.cultivator?.idProofBack && (
                              <div className="document-information ">
                                <h4> {t("back_id")} </h4>

                                <div className="uploaded-files mt-1">
                                  <img
                                    src={
                                      baseUrl +
                                      profileData?.cultivator?.idProofBack
                                    }
                                    alt=""
                                  />
                                      <a href="#" onClick={() => handleShow_img_info(baseUrl + profileData?.cultivator?.idProofBack)} ><p>{t("preview_back")}
                                       </p></a>
                                  {/* <img src={require("../../assets/images/file.svg").default} alt="" /> */}
                                  {/* <a href={baseUrl+profileData?.cultivator?.verificationDocument} target="_blank"><p>Preview Doc</p></a> */}
                                </div>
                              </div>
                            )}
                        </Col>
                        <Col md={12} className="mt-3">
                            <a onClick={()=>getbankDetail(props.id)} target="_blank" className="bank-view-txt"><p><i class="fa fa-university me-2" aria-hidden="true"></i>
                            {t("view_bank")}</p></a>
                          </Col>
                      </Row>
                      </div>
                     
                    </Col>
                   
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t("reject_reason")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            maxLength={500}
            onChange={(e) => setDeactivatedReason(e.target.value)}
            className="form-control"
          ></textarea>
          <span className="text-danger">{reasonError}</span>
        </Modal.Body>
        <Modal.Footer className="pt-3">
          <Button
            variant="secondary"
            onClick={handleClose}
            className="close-btn"
          >
            {t("close")}
          </Button>
          <Button variant="primary" onClick={disableUser}>
          {t("submit")}
          </Button>
        </Modal.Footer>
      </Modal>

        {/* bank information details start */}
        <Modal show={show_bank_info} onHide={handleClose_bank_info}>
        <Modal.Header closeButton>
          <Modal.Title><b> {t("bank_info")}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bank-information-box">
            <Row>
            <Col md={6}>
                <div className='user-text'>
                  <p>{t("bank_code")}</p>
                  <h5>{bankFields?.bankCode ? bankFields.bankCode : "N/A"}</h5>
                </div>
              </Col>
            
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("acc_holder_name")}</p>
                  <h5>{bankFields?.fullName ? bankFields.fullName : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("account_type")}</p>
                  <h5>{bankFields?.accountTypeLabel ? bankFields?.accountTypeLabel : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("acc_number")}</p>
                  <h5>{bankFields?.account_numberShow ? bankFields?.account_numberShow : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("document_type")}</p>
                  <h5>{bankFields?.documentTypeLabel ? bankFields.documentTypeLabel : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("document_number")}</p>
                  <h5>{bankFields?.documentNumber ? bankFields.documentNumber : "N/A"}</h5>
                </div>
              </Col>

              <Col md={6}>
                <div className='user-text'>
                  <p>{t("birthDate")}</p>
                  <h5>{bankFields?.birthDate ? bankFields.birthDate : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("expedition_date")}</p>
                  <h5>{bankFields?.expeditionDate ? bankFields.expeditionDate : "N/A"}</h5>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose_bank_info}>
          {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* bank information details end */}
      <Modal show={show_img_info} onHide={handleClose_img_info}>
        <Modal.Header closeButton>
          <Modal.Title><b>{t("preview_image")}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bank-information-box">
            <Row>
              <Col md={12}>
                <div className="vehicle-img-box">
                  {previewImage ?
                    <img src={previewImage} alt="image" className="vehicle-img-box" /> :
                    "Loading..."}
                </div>
              </Col>

            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose_img_info}>
          {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
